import React from "react";
import { Row, Col, ProgressBar } from "react-bootstrap"; 

export default class HorizontalChart extends React.Component {  
  constructor(props) {
    super(props); 

    this.state = {}; 

    this.data = props.data;
    this.state.offset = props.offset || 50; 
    this.state.max = props.max || 300; 

    this.dataToPercents = this.dataToPercents.bind(this);     
  }

  dataToPercents(metricName) {    
    let stackData = []; 
    
    for(let item of this.data ) {
      if(!item[metricName]) continue; 
    
      stackData.push({
        name: item.name,
        color: item.party === 'Republican' ? 'republican' : 'democrat',
        percent: ((item[metricName] * 100 + this.state.offset) / this.state.max) * 100,
        value: (item[metricName] * 100).toFixed(2)
      });       
    }    

    return stackData;
  }

  buildChart(metricName) {
    const data = this.dataToPercents(metricName); 

    return (
      <div>
        <style>
          {`
          .bg-democrat {
            background-color: #5F9FB9;   
            text-shadow: -1px 1px 0 #000;
            font-size: 13px;
          }
          .bg-republican {
            background-color: #D95E63;     
            font-size: 13px;
            text-shadow: -1px 1px 0 #000;
          }
          .progress {
            background-color: #d9d9e0; 
          }
          `}
        </style>
      { data.map( item => (
          <React.Fragment key={item.name}>
            <Row>
              <Col xs={3} md={2} style={{ textAlign: 'right', marginRight: '5px', marginTop: 'auto'}}>{item.name}</Col>
              <Col xs={8} md={9} style={{ marginTop: '10px' }}><ProgressBar variant={item.color} now={item.percent} style={{height: '28px'}} label={`${item.value}%`} /></Col>
            </Row>
          </React.Fragment>
        ))
      }
      </div>
    );     
  }

  render() {

    return (
      <div>
        {this.buildChart(this.props.metric)}
      </div>
    ); 
  }
}  